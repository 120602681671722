import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import React, { useRef, useEffect, useState } from "react";
import config from "../../../config";

import {
  EntryTop,
  EntryTime,
  PostEntry,
  EntryBottom,
  EntryCaption,
  EntryChannels
} from "./styles";
import Icon from "components/common/Icon";
import { formatForTimeline } from "utils/date";
import {
  PostContextMenuWithButton,
  RightClickPostContextMenu
} from "../common/postContextMenu/PostContextMenu";
import { ACTIVITY_TYPE } from "../constants/activityTypes";
import { useComposerActions } from "contextApi/composerContext";
import EntryStatus from "components/common/contentTypes/EntryStatus";
import { Avatar, ChannelAvatar, Service } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import contentTypes from "components/common/contentTypes/data/content-types";

function MonthPost({
  entry,
  customChannels = null,
  dashboardEntry = false,
  handleExpandGroup = null
}) {
  const { type, isPhantom, draft } = entry;
  const post = entry.draft || entry.post;
  const isDraft = type === ACTIVITY_TYPE.DRAFT_POST;
  const draftText =
    !!config?.systemUserId && config?.systemUserId == draft?.scheduledBy
      ? "Draft by Will"
      : "Draft";
  const isPostGroup = type === ACTIVITY_TYPE.POST_GROUP;

  const ref = useRef(null);
  const draggingItemId = useSelector(state => state.dragDrop.draggingItemId);
  const openPost = useComposerActions(actions => actions.openPost);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const channels =
    isDraft || isPostGroup ? post.channels : customChannels ?? [post.channel];
  const isDragging = draggingItemId === post.id;

  const displayTime = format(
    parseISO(post.scheduledAt ?? post.postedAt),
    "H:mm"
  );

  const displayDate = formatForTimeline(
    new Date(post.postedAt || post.scheduledAt),
    true
  );

  const postIcon = {
    photo: "icon-image-filled",
    video: "icon-video-filled",
    article: "icon-clicks",
    pdf: "icon-pdf-filled"
  };

  const caption =
    isDraft || isPostGroup
      ? Object.values(post.caption).sort((a, b) => {
          return b.length - a.length;
        })[0]
      : post.caption;

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
    };
  }, [contextMenuPos]);

  return (
    <PostEntry
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        if (handleExpandGroup) {
          handleExpandGroup();
        } else {
          openPost(post);
        }
      }}
      isPhantom={isPhantom}
      borderColor={contentTypes[post.contentTypeId]?.color}
      isGroup={!post.isDraft && (customChannels || post.isPostGroup)}
      isDragging={isDragging}
      isContextMenuOpen={contextMenuPos.length > 0}
      dashboardEntry={dashboardEntry}
    >
      <EntryTop>
        <EntryTime>
          {dashboardEntry ? (
            <>
              {isDraft ? (
                <span className="draft">{`${displayDate} | Draft`}</span>
              ) : (
                displayDate
              )}
            </>
          ) : (
            <>
              <EntryStatus
                status={
                  post.isStuck ? "STUCK" : post.isDraft ? "DRAFT" : post.status
                }
              />
              {isDraft ? (
                <span className="draft">{`${displayTime} ${draftText}`}</span>
              ) : (
                displayTime
              )}
            </>
          )}
        </EntryTime>
        <EntryChannels adjustWidth={post.isDraft || post.isPostGroup}>
          {channels.map((channel, idx) => (
            <ChannelAvatar ml={2} key={`${channel.id}-${idx}`} $enabled={true}>
              <Avatar
                src={channel.avatar ?? defaultAvatar}
                width={20}
                height={20}
                $noShadow={true}
                $isRounded={true}
                onError={event => {
                  event.target.src = defaultAvatar;
                  event.onerror = null;
                }}
              />
              <Service
                key={channel.id}
                title={channel.username}
                $type={channel.service}
                $size={10}
              />
            </ChannelAvatar>
          ))}
        </EntryChannels>
      </EntryTop>
      <EntryBottom ml={2}>
        {post.attachment ? (
          <Icon
            icon={`${postIcon[post.attachment.type]}`}
            variant="light"
            size="md"
          />
        ) : (
          <Icon icon={"icon-text-filled"} variant="light" size="md" />
        )}
        <EntryCaption dashboardEntry={dashboardEntry}>{caption}</EntryCaption>
      </EntryBottom>
      <RightClickPostContextMenu
        entry={{ ...entry, post: entry.draft || entry.post }}
        closeMenu={closeMenu}
        contextMenuPos={contextMenuPos}
      />
      <PostContextMenuWithButton
        entry={{ ...entry, post: entry.draft || entry.post }}
        contextWithPortal={true}
      />
    </PostEntry>
  );
}

export default MonthPost;
