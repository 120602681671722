const API_URL = process.env.REACT_APP_API_URL;

const config = {
  development: {
    rootUrl: "http://localhost:3000",
    apiUrl: process.env.REACT_APP_API_URL || "http://localhost:4001",
    chargebee: {
      site: "hellocontento-test"
    },
    google: {
      clientId:
        "867273570146-80vovot84ioatokb45do11auoqblprn2.apps.googleusercontent.com"
    },
    systemUserId: ""
  },
  staging: {
    rootUrl: process.env.PUBLIC_URL,
    apiUrl: API_URL,
    chargebee: {
      site: "hellocontento-test"
    },
    google: {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
    },
    systemUserId: "083e0c8d-0fc0-4911-b749-32eccee33e04"
  },
  production: {
    rootUrl: process.env.PUBLIC_URL,
    apiUrl: API_URL,
    chargebee: {
      site: "hellocontento"
    },
    google: {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
    },
    systemUserId: "92eae893-ac57-40ce-9024-0370417105a2"
  }
};

const ENV = process.env.REACT_APP_ENV || "development";

module.exports = config[ENV];
